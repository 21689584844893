.closure-notice-page {
  display: flex;
  width: 100%;
  height: 100vh;
}

.banner-display {
  background-image: url("../public/assets/app-images/Banner_end_of_support_desktop.jpg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
  background-size: contain;
}

@media (max-width: 600px) {
  .banner-display {
    background-size: 100%;
    background-image: url("../public/assets/app-images/Banner_end_of_support_mobile.jpg");
  }
  .closure-notice-page {
    min-height: calc(100vh * 2.5);
  }
}

@media (max-width: 450px) {
  .closure-notice-page {
    min-height: calc(100vh * 1.15);
  }
}
